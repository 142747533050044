import { useHasRole, useAuth } from 'hooks/useAuth';
import PrivateLayoutPage from 'layouts/PrivateLayout';
import { flatMap, map } from 'lodash';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import loadable from 'utils/loadable';

const routes = [
  {
    path: '/segments',
    routes: [
      {
        path: '/patronSegments/create',
        component: loadable(import('pages/segments/patronSegments/Create')),
      },
      {
        path: '/patronSegments/:id/edit',
        component: loadable(import('pages/segments/patronSegments/Edit')),
      },
      {
        path: '/patronSegments/:id',
        component: loadable(import('pages/segments/patronSegments/Show')),
      },
      {
        path: '/storeSegments/create',
        component: loadable(import('pages/segments/storeSegments/Create')),
      },
      {
        path: '/storeSegments/:id/edit',
        component: loadable(import('pages/segments/storeSegments/Edit')),
      },
      {
        path: '/storeSegments/:id/addStore',
        component: loadable(
          import('components/storeSegments/ListStoreDontExistInSegment'),
        ),
      },
      {
        path: '/storeSegments/:id/viewStore',
        component: loadable(
          import('components/storeSegments/ListStoreForFilterBase'),
        ),
      },
      {
        path: '/storeSegments/:id',
        component: loadable(import('pages/segments/storeSegments/Show')),
      },
      {
        path: '/patronSegments',
        component: loadable(import('pages/segments/patronSegments/List')),
      },
      {
        path: '/storeSegments',
        component: loadable(import('pages/segments/storeSegments/List')),
      },
    ],
  },
  {
    path: '/adminFees',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/adminFees/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/adminFees/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/adminFees/Edit')),
      },
    ],
  },
  {
    path: '/',
    component: loadable(import('pages/Home')),
    exact: true,
    title: 'dashboard.title',
  },
  {
    path: '/storeManagement',
    routes: [
      {
        path: '/create',
        component: loadable(import('pages/stores/Create')),
        exact: true,
        title: 'stores.header',
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/stores/Edit')),
      },
      {
        path: '',
        component: loadable(import('pages/stores/List')),
      },
    ],
  },
  {
    path: '/contactManagement',
    routes: [
      {
        path: '/create',
        component: loadable(import('pages/contacts/Create')),
        exact: true,
      },
      {
        path: '/:id',
        component: loadable(import('pages/contacts/Edit')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/contacts/Edit')),
      },
      {
        path: '',
        component: loadable(import('pages/contacts/List')),
      },
    ],
  },
  {
    path: '/invoiceTracking',
    exact: true,
    routes: [
      {
        path: '/sandsLoyalty',
        component: loadable(import('pages/invoices/sandsLoyalty/List')),
        exact: true,
        title: 'invoices.sandsLoyaltyTitle',
      },
      {
        path: '/eVoucher',
        component: loadable(import('pages/invoices/eVoucher/List')),
        exact: true,
        title: 'invoices.eVoucherTitle',
      },
    ],
  },
  {
    path: '/contents',
    exact: true,
    title: 'contents.header',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/contents/List')),
        exact: true,
        title: 'contents.header',
      },
      {
        path: '/create',
        component: loadable(import('pages/contents/Create')),
        exact: true,
        title: 'stores.header',
      },
      {
        path: '/:id',
        exact: true,
        component: loadable(import('pages/contents/Edit')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/contents/Edit')),
      },
    ],
  },
  {
    path: '/transactions',
    exact: true,
    title: 'transactions.header',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/transactions/List')),
        exact: true,
        title: 'transactions.header',
      },
      {
        path: '/create',
        component: loadable(import('pages/transactions/Create')),
        exact: true,
        title: 'transactions.header',
      },
      {
        path: '/:id/adjustment/:membershipNo',
        component: loadable(import('pages/transactions/Create')),
        exact: true,
        title: 'transactions.header',
      },
      {
        path: '/:id',
        component: loadable(import('pages/transactions/Detail')),
        exact: true,
        title: 'transactions.header',
      },
    ],
  },
  {
    path: '/sl-transaction',
    exact: true,
    title: 'slTransaction.title',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/sl-transaction/List')),
        exact: true,
        title: 'slTransactions.title',
      },
      {
        path: '/create',
        component: loadable(import('pages/sl-transaction/Create')),
        exact: true,
        title: 'slTransactions.title',
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/sl-transaction/Edit')),
        title: 'slTransactions.title',
      },
      {
        path: '/:id',
        component: loadable(import('pages/sl-transaction/Detail')),
        title: 'slTransactions.title',
      },
    ],
  },
  {
    path: '/admin',
    exact: true,
    routes: [
      {
        path: '/storeMapping',
        component: loadable(import('pages/payment-type-mapping/List')),
        exact: true,
        title: 'adminSettings.storeMapping.title',
      },
    ],
  },
  {
    path: '/retailUsers',
    exact: true,
    title: 'retailUsers.header',
    routes: [
      {
        path: '/retailerUserManagement/:id/edit',
        component: loadable(
          import('pages/retailUsers/retailUsersManagement/Edit'),
        ),
        title: 'users.header',
      },
      {
        path: '/retailerUserManagement/create',
        component: loadable(
          import('pages/retailUsers/retailUsersManagement/Create'),
        ),
        title: 'users.header',
      },
      {
        path: '/retailerUserManagement',
        component: loadable(
          import('pages/retailUsers/retailUsersManagement/List'),
        ),
        exact: true,
        title: 'users.header',
      },
      {
        path: '/retailAccessTypes',
        component: loadable(import('pages/retailUsers/retailAccessType/List')),
        exact: true,
        title: 'userAccessTypes.header',
      },
      {
        path: '/retailAccessManagement',
        component: loadable(
          import('pages/retailUsers/retailAcessManagement/List'),
        ),
        exact: true,
        title: 'userProfile.header',
      },
    ],
  },
  {
    path: '/tmsUsers',
    exact: true,
    title: 'retailUsers.header',
    routes: [
      {
        path: '/tmsUserManagement/:id/edit',
        component: loadable(import('pages/tmsUsers/tmsUsersManagement/Edit')),
        title: 'users.header',
      },
      {
        path: '/tmsUserManagement/create',
        component: loadable(import('pages/tmsUsers/tmsUsersManagement/Create')),
        title: 'users.header',
      },
      {
        path: '/tmsUserManagement',
        component: loadable(import('pages/tmsUsers/tmsUsersManagement/List')),
        exact: true,
        title: 'users.header',
      },
      {
        path: '/tmsAccessTypes',
        component: loadable(import('pages/tmsUsers/tmsAccessType/List')),
        exact: true,
        title: 'userAccessTypes.header',
      },
      {
        path: '/tmsAccessManagement',
        component: loadable(import('pages/tmsUsers/tmsAccessManagement/List')),
        exact: true,
        title: 'userProfile.header',
      },
    ],
  },
  {
    path: '/campaigns',
    routes: [
      {
        path: '/:id/edit',
        component: loadable(import('pages/campaign/Edit')),
      },
      {
        path: '/create',
        component: loadable(import('pages/campaign/Create')),
      },
      {
        path: '/',
        component: loadable(import('pages/campaign/List')),
      },
    ],
  },
  {
    path: '/reports',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/reports/index')),
      },
      {
        path: '/:type',
        component: loadable(import('pages/reports/index')),
      },
    ],
  },
  {
    path: '/turnkeyHistoricalReports',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/turnkeyHistoricalReports/index')),
      },
      {
        path: '/:type',
        component: loadable(import('pages/turnkeyHistoricalReports/index')),
      },
    ],
  },
  {
    path: '/lookup',
    routes: [
      {
        path: '/level/:id/edit',
        component: loadable(import('components/lookup/level/Edit')),
      },
      {
        path: '/level',
        component: loadable(import('components/lookup/level/List')),
        exact: true,
      },
      {
        path: '/category/:id/edit',
        component: loadable(import('components/lookup/category/Edit')),
      },
      {
        path: '/category',
        component: loadable(import('components/lookup/category/List')),
        exact: true,
      },
      {
        path: '/gst/:id/edit',
        component: loadable(import('components/lookup/gst/Edit')),
      },
      {
        path: '/gst/:id',
        component: loadable(import('components/lookup/gst/Edit')),
        exact: true,
      },
      {
        path: '/gst',
        component: loadable(import('components/lookup/gst/List')),
        exact: true,
      },
      {
        path: '/cardtier/:id/edit',
        component: loadable(import('components/lookup/cardtier/Edit')),
      },
      {
        path: '/cardtier',
        component: loadable(import('components/lookup/cardtier/List')),
        exact: true,
      },
      {
        path: '/:type',
        component: loadable(import('pages/lookup/index')),
      },
    ],
  },
  {
    path: '/watchlist',
    exact: true,
    routes: [
      {
        path: '/ruleList/create',
        component: loadable(
          import('components/watchlist/watchlistRules/Create'),
        ),
      },
      {
        path: '/ruleList/:id',
        component: loadable(import('components/watchlist/watchlistRules/Edit')),
      },
      {
        path: '/ruleList/:id/edit',
        component: loadable(import('components/watchlist/watchlistRules/Edit')),
      },
      {
        path: '/ruleList',
        component: loadable(import('components/watchlist/watchlistRules/List')),
        exact: true,
      },

      {
        path: '/monthSummary',
        component: loadable(import('components/watchlist/monthSummary/List')),
      },
      {
        path: '/transactions',
        component: loadable(
          import('components/watchlist/watchTransactionList/List'),
        ),
      },
      {
        path: '/releaseList',
        component: loadable(import('components/watchlist/releaseList/List')),
      },
    ],
  },
  {
    path: '/eVoucherUtilization',
    exact: true,
    routes: [
      {
        path: '/',
        component: loadable(import('pages/eVoucherUtilization/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/eVoucherUtilization/Create')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/eVoucherUtilization/Detail')),
      },
    ],
  },
];

const groupRoutes = flatMap(routes, (route) => {
  if (route.routes) {
    return map(route.routes, (subRoute) => ({
      ...subRoute,
      path: route.path + subRoute.path,
      exact: subRoute.path === '/',
      component: subRoute.component || route.component,
    }));
  }
  return route;
});

function PrivateRoute({ title, roles, component: Component, ...rest }) {
  const hasRole = useHasRole(roles);
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const convertPathName = pathname.split('/');
  const hasTmUser = convertPathName.includes('tmuser');

  return hasRole ? (
    <Route
      {...rest}
      render={
        (props) =>
          isAuthenticated ? (
            <PrivateLayoutPage>
              <Component />
            </PrivateLayoutPage>
          ) : (
            <Redirect
              to={{
                pathname:
                  hasTmUser || JSON.parse(localStorage.getItem('isTmUser'))
                    ? '/tmlogin'
                    : '/retaillogin',
                // eslint-disable-next-line
                state: { from: props.location },
              }}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  ) : (
    <Route render={null} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  hasPrivateLayoutWrapper: PropTypes.bool,
  roles: PropTypes.array,
};

const PrivateRoutes = () =>
  map(groupRoutes, (route) => <PrivateRoute {...route} key={route.path} />);

export default PrivateRoutes;
