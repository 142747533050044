import {
  DashboardOutlined,
  DollarOutlined,
  FormOutlined,
  HomeOutlined,
  PieChartOutlined,
  UserOutlined,
  ContainerOutlined,
  ContactsOutlined,
  PercentageOutlined,
  FileTextOutlined,
  NotificationOutlined,
  SearchOutlined,
  EyeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAccessRoutes from './useAccessRoutes';
import VoucherIcon from '../assets/icons/voucher_icon.svg';

export const useRoutes = () => {
  const { t } = useTranslation();

  const ROUTES = useMemo(
    () => [
      {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardOutlined />,
      },
      {
        path: '/transactions',
        name: t('transactions.title'),
        icon: <DollarOutlined />,
        key: 'TRANSACTION_LISTING_AND_POSTING',
        roles: ['RetailStaff', 'RetailManager'],
      },
      {
        path: '/sl-transaction',
        name: t('slTransaction.title'),
        icon: <DollarOutlined />,
        key: 'SL_TRANSACTION',
      },
      {
        path: '/admin',
        name: t('adminSettings.title'),
        key: 'ADMIN_STORE_PAYMENT_TYPE_MAPPING',
        icon: <SettingOutlined />,
        routes: [
          {
            key: 'STORE_PAYMENT_TYPE_MAPPING',
            path: 'storeMapping',
            name: t('adminSettings.storeMapping.title'),
          },
        ],
      },
      {
        path: '/eVoucherUtilization',
        name: t('eVoucherUtilization.title'),
        icon: (
          <img
            src={VoucherIcon}
            alt="voucher-icon"
            width={19}
            height={19}
            className="mr-3 mb-1"
          />
        ),
        key: 'EVOUCHER_TRANSACTION',
        roles: ['RetailStaff', 'RetailManager'],
      },
      {
        path: '/retailUsers/retailerUserManagement',
        name: t('retailUsers.title'),
        key: 'USER_ACCESS_RETAILER',
        icon: <UserOutlined />,
      },
      {
        path: '/tmsUsers/tmsUserManagement',
        name: t('tmsUsers.title'),
        key: 'USER_ACCESS_TM',
        icon: <UserOutlined />,
      },
      {
        path: '/storeManagement',
        name: t('stores.title'),
        key: 'STORE_MANAGER',
        icon: <HomeOutlined />,
      },
      {
        path: '/contactManagement',
        name: t('contact.title'),
        key: 'CONTACT_MANAGER',
        icon: <ContactsOutlined />,
      },
      {
        path: '/contents',
        name: t('contents.header'),
        key: 'CONTENT_MANAGER',
        icon: <FormOutlined />,
      },
      {
        path: '/adminFees',
        name: t('adminFees.header'),
        key: 'ADMIN_FEE_MAINTENANCE',
        icon: <PercentageOutlined />,
      },
      {
        path: '/segments/storeSegments',
        name: t('segments.header'),
        key: 'STORE_SEGMENT',
        icon: <PieChartOutlined />,
      },
      {
        path: '/campaigns',
        name: t('campaigns.header'),
        key: 'CAMPAIGN',
        icon: <NotificationOutlined />,
      },
      {
        path: '/invoiceTracking',
        name: t('invoices.title'),
        key: 'INVOICE_TRACKING_MODULE',
        icon: <ContainerOutlined />,
        routes: [
          {
            key: 'INVOICE_TRACKING',
            path: 'sandsLoyalty',
            name: t('invoices.sandsLoyaltyTitle'),
          },
          {
            key: 'INVOICE_TRACKING_EVOUCHER',
            path: 'eVoucher',
            name: t('invoices.eVoucherTitle'),
          },
        ],
      },
      {
        path: '/reports',
        name: t('reports.header'),
        key: 'REPORT',
        icon: <FileTextOutlined />,
      },
      {
        path: '/turnkeyHistoricalReports',
        name: t('turnkeyHistoricalReports.header'),
        key: 'TURNKEY_HISTORICAL',
        icon: <FileTextOutlined />,
      },
      {
        path: '/lookup/level',
        name: t('lookup.header'),
        key: 'LEVELS_MAINTENANCE',
        icon: <SearchOutlined />,
      },
      {
        path: '/watchlist/ruleList',
        name: t('watchlist.header'),
        key: 'WATCHLIST_MANAGEMENT',
        icon: <EyeOutlined />,
      },
    ],
    [t],
  );
  const [sidebarWithPermission] = useAccessRoutes(ROUTES);
  return {
    routes: sidebarWithPermission,
  };
};
